import { ApplicationStatusType } from "../Type/ApplicationStatusType";

export class ApplicationStatusModel {
  private _id: number;
  private _name: string;
  private _color: string | null;
  private _type: string;
  private _published: boolean;

  constructor(applicationStatusId: number, included: ApplicationStatusType[]) {
    let applicationStatus: ApplicationStatusType = included.find(
      (item) => item.id === applicationStatusId
    );
    this._id = applicationStatus.id;
    this._name = applicationStatus.attributes.name;
    this._color = applicationStatus.attributes.color;
    this._type = applicationStatus.type;
    applicationStatus.attributes.name === "Published"
      ? (this._published = true)
      : (this._published = false);
  }

  get id(): number {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get color(): string | null {
    return this._color;
  }

  set color(value) {
    this._color = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value) {
    this._type = value;
  }

  get published(): boolean {
    return this._published;
  }

  set published(value) {
    this._published = value;
  }
}
