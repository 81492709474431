import banner from '../Assets/Img/TDT-Logo-Desktop.svg'
const Header = (props) => {
    return (
        <div className='box header'>
            <h1>
                Brindamos soluciones para digitalizar y automatizar procesos
                comerciales y operativos.
            </h1>
            <div className='logo'>
                <img src={banner} alt='' />
            </div>
        </div>
    )
}

export default Header
