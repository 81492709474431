//@ts-ignore
import { utility } from '@tdt-global/utility'
// @ts-ignore
import { Loading } from '@tdt-global/styleguide'

import { lazy, Suspense } from 'react'
import Footer from './Components/Footer'
import Header from './Components/Header'
import Body from './Components/Body'

// const Body = lazy(() => import("./Components/Body"));

export default function Root(props) {
    const { RenderIfToken } = utility

    return (
        <RenderIfToken>
            <div id='tdt-global-suite-apps'>
                <Header />
                {/* <Suspense fallback={<Loading />}> */}
                <Body />
                {/* </Suspense> */}
                <Footer />
            </div>
        </RenderIfToken>
    )
}
