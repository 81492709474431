// @ts-ignore
import { IconsList } from '@tdt-global/styleguide'
// @ts-ignore
import { Button } from '@tdt-global/styleguide'
//@ts-ignore
import { utility } from '@tdt-global/utility'
// @ts-ignore
import { Skeleton, List, Avatar } from 'antd'
import useSWR from 'swr'
import { Endpoints } from '../Config/Endpoints'
import { useCallback, useEffect, useState } from 'react'
import { ApplicationType } from '../Type/ApplicationType'
import { ApplicationModel } from '../Models/ApplicationModel'

const Body = () => {
    const { useAxios, LocalStorage } = utility
    const { getHttpRequestSWR } = useAxios()
    let iconMessage = IconsList({ name: 'MailOutlined' })
    let iconThunder = IconsList({ name: 'ThunderboltOutlined' })
    const [appList, setAppList] = useState<ApplicationModel[]>([])

    const load = (url) => getHttpRequestSWR(url, { 'sort[0]': 'createdAt' })

    const { data, error }: any = useSWR(Endpoints.GET_APPLICATIONS, load)

    const setAppListHandle = useCallback(() => {
        setAppList(
            data?.data.map(
                (item: ApplicationType) =>
                    new ApplicationModel(item, data?.included),
            ),
        )
    }, [data])

    useEffect(() => {
        data && setAppListHandle()
    }, [data])

    const handleClick = (id: number) => {
        LocalStorage.setApplication(id.toString())
    }

    return (
        <div className='box body'>
            <h2>
                Comienza a utilizar nuestras herramientas. Agiliza tu forma de
                planificar, organiza tu inventario y da seguimiento a tus
                campañas.
            </h2>
            {!data && (
                <div className={`skeleton`}>
                    <Skeleton loading={!data} active />
                    <Skeleton loading={!data} active />
                    <Skeleton loading={!data} active />
                    <Skeleton loading={!data} active />
                </div>
            )}
            <div className={`apps-list ${!data ? 'load' : ''}`}>
                {appList.map(
                    (
                        {
                            client_name,
                            icon,
                            application_status,
                            enabled,
                            version,
                            logo,
                            id,
                        },
                        index: any,
                    ) => {
                        let link = `/${client_name}`
                        return (
                            <div key={index}>
                                <a
                                    href={
                                        enabled &&
                                        application_status.published &&
                                        link
                                    }
                                    onClick={() => handleClick(id)}
                                    target={'_blank'}>
                                    <div
                                        className={`app ${
                                            enabled &&
                                            application_status.published
                                                ? ''
                                                : 'activa'
                                        }`}>
                                        {icon && (
                                            <span className='icon'>
                                                <img
                                                    src={icon}
                                                    typeof='image/avif'
                                                    loading='lazy'
                                                    decoding='async'
                                                />
                                            </span>
                                        )}
                                        {logo && (
                                            <div className='logo'>
                                                <img
                                                    src={logo}
                                                    typeof='image/avif'
                                                    loading='lazy'
                                                    decoding='async'
                                                />
                                            </div>
                                        )}
                                        {application_status.published ? (
                                            enabled ? (
                                                <h5>Versión {version}</h5>
                                            ) : (
                                                <Button ghost size={'small'}>
                                                    Adquirir
                                                    {iconMessage}
                                                </Button>
                                            )
                                        ) : (
                                            <span className='comming-soon'>
                                                <h5>Próximamente</h5>
                                            </span>
                                        )}
                                    </div>
                                </a>
                                {enabled && application_status.published && (
                                    <Button ghost size={'small'}>
                                        Mira las novedades
                                        {iconThunder}
                                    </Button>
                                )}
                            </div>
                        )
                    },
                )}
            </div>
        </div>
    )
}

export default Body
