// @ts-ignore
import { IconsList } from "@tdt-global/styleguide";
import ContactForm from "./ContactForm";

const Footer = (props) => {
  let iconPhone = IconsList({ name: "PhoneOutlined" });
  let iconLinkedin = IconsList({ name: "LinkedinOutlined" });
  let iconMessage = IconsList({ name: "MailOutlined" });

  return (
    <div className="box footer">
      <div className="form">
        <div className="title">Contáctanos</div>
        <ContactForm />
      </div>
      <div className="contact">
        <div className="title">Nuestras oficinas</div>
        <div className="places">
          <div>
            <div className="title">Uruguay</div>
            <div className="ubication">
              Ramon Masini 3192, Pocitos, Montevideo
            </div>
            <div className="info">
              <div className="icon">{iconPhone}</div>
              <div className="text">+59892272792</div>
            </div>
            <div className="info">
              <div className="icon">{iconMessage}</div>
              <div className="text">info@tdtglobal.io</div>
            </div>
            <a
              target={"_blank"}
              href="https://www.linkedin.com/company/tdtglobal"
            >
              <div className="info">
                <div className="icon">{iconLinkedin}</div>
                <div className="text">/tdtglobal</div>
              </div>
            </a>
          </div>
          <div>
            <div className="title">United States</div>
            <div className="ubication">1675 S STATE ST STE B 19901, Dover</div>
            <div className="info">
              <div className="icon">{iconPhone}</div>
              <div className="text">+14046000396</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
