import { ApplicationStatusType } from "../Type/ApplicationStatusType";
import { ApplicationType } from "../Type/ApplicationType";
import { ApplicationStatusModel } from "./ApplicationStatusModel";

export class ApplicationModel {
  private _id: number;
  private _name: string;
  private _description: string | null;
  private _client_name: string;
  private _version: string | null;
  private _enabled: boolean | null;
  private _icon: string | null;
  private _logo: string | null;
  private _application_status_id: number;
  private _type: string;
  private _application_status: ApplicationStatusModel;

  constructor(application: ApplicationType, included: any) {
    this._id = application.id;
    this._name = application.attributes.name;
    this._description = application.attributes.description;
    this._client_name = application.attributes.client_name;
    this._enabled = application.attributes.enabled;
    this._version = application.attributes.version;
    this._icon = application.attributes.icon;
    this._logo = application.attributes.logo;
    this._application_status_id = application.attributes.application_status_id;
    this._type = application.type;
    this._application_status = new ApplicationStatusModel(
      application.attributes.application_status_id,
      included
    );
  }

  get id(): number {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get version(): string | null {
    return this._version;
  }

  set version(value) {
    this._version = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get description(): string | null {
    return this._description;
  }

  set description(value) {
    this._description = value;
  }

  get client_name(): string {
    return this._client_name;
  }

  set client_name(value) {
    this._client_name = value;
  }

  get enabled(): boolean | null {
    return this._enabled;
  }

  set enabled(value) {
    this._enabled = value;
  }
  get icon(): string | null {
    return this._icon;
  }

  set icon(value) {
    this._icon = value;
  }

  get logo(): string | null {
    return this._logo;
  }

  set logo(value) {
    this._logo = value;
  }

  get application_status_id(): number {
    return this._application_status_id;
  }

  set application_status_id(value) {
    this._application_status_id = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value) {
    this._type = value;
  }

  get application_status(): ApplicationStatusModel {
    return this._application_status;
  }

  set application_status(value) {
    this._application_status = value;
  }
}
