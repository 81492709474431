// @ts-ignore
import { Form, Input } from 'antd'
// @ts-ignore
import { Button, Checkbox } from '@tdt-global/styleguide'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { useEffect, useState } from 'react'

const ContactForm = () => {
    const { useUser } = utility
    const [form] = Form.useForm()
    let { user, isLoading, isError } = useUser()

    useEffect(() => {
        user?.first_name &&
            user?.email &&
            form.setFieldsValue({
                username: user?.first_name + ' ' + user?.last_name,
                email: user?.email,
            })
    }, [user])

    const onFinish = (values: any) => {
        console.log(values)
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }
    return (
        <>
            <Form
                name='basic'
                layout='vertical'
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'>
                <Form.Item
                    label='Nombre'
                    name='username'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}>
                    <Input
                        size='large'
                        type='text'
                        placeholder='Ej. juanperez'
                    />
                </Form.Item>

                <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                        { required: true, message: 'Please input your email!' },
                    ]}>
                    <Input
                        size='large'
                        type='email'
                        placeholder='Ej. juanperez@correo.com'
                    />
                </Form.Item>

                <Form.Item
                    label='Mensaje'
                    name='message'
                    rules={[
                        { required: true, message: 'Please input your text!' },
                    ]}>
                    <Input.TextArea
                        placeholder='Escribe aquí tu mensaje.'
                        size='large'
                        autoSize={{ minRows: 4, maxRows: 6 }}
                    />
                </Form.Item>

                <Form.Item style={{ textAlign: 'right' }}>
                    <Button type='primary' htmlType='submit'>
                        Enviar
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default ContactForm
